#popoverEffects {
  max-height: '50vh';
  box-shadow: 5px 5px 20px 2px rgb(0 0 0 / 50%);
  display: flex;
  width: 302px;
  max-width: unset;
  z-index: 10001;

  .backgroundsThumbsContainer {
    display: flex;
    flex-flow: row wrap;
    width: 300px;
  }

  .effectItem {
    cursor: pointer;
    width: 150px;
    align-items: center;
    justify-content: center;
  }

  .turnOff {
    border-top: none;
    flex-grow: 1;
    height: 84px;
  }

  .card:hover {
    filter: brightness(0.85);
  }
}
