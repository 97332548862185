.pipLayout {
  #body {
    display: block;
    height: calc(100% - var(--footer-height) - var(--header-height));

    #mainVideoPlaceholder {
      grid-area: vMain;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;

      .remoteVideoContainer {
        height: 100%;
      }

      .videoContainer {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        video {
          height: 100%;
          width: 100%;
          max-width: 1200px;
        }

        .videoContainer_noCamera {
          width: 90%;
          height: 100%;
          background-size: 300px;

          background-repeat: no-repeat;
          background-position: center;
          background-color: #333;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          left: 50%;
        }

        .userStatusContainer {
          position: absolute;
          bottom: 12px;
          height: 24px;

          .micFeedback {
            display: inline-block;
            top: -5px;
            left: 2px;
            width: 25px;
            position: relative;
            margin-top: 0px;
          }
        }
      }
    }
    #participantsPlaceholder {
      .videosContainer {
        display: block;
        border-left: 1px solid #ccc;

        .videoContainer:first-of-type {
          transition: right 0.5s ease-in-out;
          position: absolute;
          bottom: 20px;
          right: 20px;
          width: 250px;
          height: 200px;
          max-width: 30vh;
          max-height: 30vw;
          z-index: 1050;
        }

        .videoContainer:not(:first-of-type) {
          display: none;
        }

        .videoContainer {
          .videoContainer_noCamera {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            height: 100%;
            width: 100%;
            position: absolute;
            background-color: rgba(255, 255, 255, 0.15);
            border-radius: 10px;
          }

          video {
            object-fit: cover !important;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            box-shadow: 5px 5px 20px 2px rgb(0 0 0 / 50%);
          }

          .userStatusContainer {
            top: 85%;
            position: absolute;
            width: 100%;
          }
        }
      }
    }
  }

  @media (max-aspect-ratio: 3/4) {
    :root {
      --footer-height: 65px;
    }

    #body {
      height: calc(100% - var(--header-height) - var(--footer-height)) !important;
      display: block;
      grid-template-areas: unset;

      .nonBlockingModal {
        top: 30%;
      }

      #mainVideoPlaceholder {
        height: 100%;
        width: 100%;
        min-height: 100%;
        grid-area: unset;
      }
      #participantsPlaceholder .videosContainer .videoContainer {
        &:first-of-type {
          bottom: 40px;
        }
        display: block;
        width: 30vw !important;
        height: 30vw !important;
        max-width: 30vh;
        max-height: 30vw;

        .userStatusContainer * {
          display: none;
        }
      }
    }
  }

  /** usamos 560px para que este css seja aplicado em celulares na horizontal. */
  @media (max-height: 560px) {
    --header-height: 50px;
    --footer-height: 55px;

    :root {
      --header-height: 50px;
      --footer-height: 55px;
    }

    &.myRoom.ready .header {
      height: var(--header-height);

      .leftHeader {
        background-size: 150px;
      }
    }

    &.myRoom.ready #body {
      height: calc(100% - var(--header-height) - var(--footer-height)) !important;
      top: var(--header-height);

      #mainVideoPlaceholder {
        .userStatusContainer {
          bottom: 0px !important;
        }
      }

      #participantsPlaceholder .videosContainer .videoContainer {
        height: 100px;

        video {
          height: 100px;
        }
        .userStatusContainer {
          top: 79%;
        }
      }
    }

    #footer {
      height: var(--footer-height);

      .toolbar {
        height: var(--footer-height);
      }
    }
  }
}
