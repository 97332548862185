.nonBlockingModal.conexaoLentaModal {
  box-shadow: 0 0 1px 5000px rgba(10, 10, 10, 0.25);
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  height: 100px;
  color: #222;

  top: 40%;
  font-weight: 400;
  width: 100px;
  height: 110px;
  line-height: 19px;

  .fa-layers.slowConnection {
    position: relative;
    width: 100%;
    height: 30px;
    font-size: 35px;
    top: 0px !important;

    .icon.slowConnection {
      left: 0;
    }
  }
}
