body,
html {
  height: 100%;
}

body {
  min-height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.btn-circle.btn-sm {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  font-size: 8px;
  text-align: center;
}
.btn-circle.btn-md {
  width: 50px;
  height: 50px;
  padding: 0px 14px;
  border-radius: 25px;
  font-size: 10px;
  text-align: center;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 12px;
  text-align: center;
}

.btn-circle-container {
  width: 70px;
  display: inline-flex;
  flex-direction: column;
  padding: 0px 0px 0px 0px;
  margin-left: 0px;
  margin-right: 0px;
  justify-content: flex-start;
  align-items: center;
}

.btn-circle-container label {
  color: #fff;
  font-size: 11px;
  margin: 2px;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 0.2;
}

.opacity-2 {
  opacity: 0.4;
}

.opacity-3 {
  opacity: 0.6;
}

.opacity-4 {
  opacity: 0.8;
}

.opacity-5 {
  opacity: 1;
}

.hidden {
  display: none !important;
}

/* trick para que o botao do modal nao cancele a animacao de fechamento do modal */
.modal-footer .btn {
  transition: none;
}

#root {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
