.centeredLayout {
 
  #body {
    display: grid;
    grid-template-areas: 'vMain vMain vMain vMain vSide';

    #mainVideoPlaceholder {
      grid-area: vMain;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;

      .videoContainer {
        height: 100%;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        video {
          height: 100%;
          width: 100%;
          background-color: transparent;
        }

        .videoContainer_noCamera {
          width: 90%;
          height: 100%;
          background-size: 300px;

          background-repeat: no-repeat;
          background-position: center;
          background-color: #333;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          left: 50%;
        }

        .userStatusContainer {
          position: absolute;
          bottom: 12px;
          height: 24px;

          .micFeedback {
            display: inline-block;
            top: -5px;
            left: 2px;
            width: 25px;
            position: relative;
            margin-top: 0px;
          }
        }
      }
    }
    #participantsPlaceholder {
      grid-area: vSide;
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: #222;
      box-shadow: 1px 0px 10px 1px rgb(0 0 0 / 50%);
      overflow-y: auto;
      overflow-x: hidden;

      .videosContainer {
        display: block;

        .videoContainer {
          height: 160px;
          margin-bottom: 10px;

          .videoContainer_noCamera {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            height: 100%;
            width: 100%;
            position: absolute;
          }

          video {
            height: 160px;
            width: 100%;
            object-fit: contain !important;
          }

          .userStatusContainer {
            top: 85%;
            position: absolute;
            width: 100%;
          }
        }
      }
    }
  }

  @media (max-aspect-ratio: 3/4) {
    #body {
      height: calc(100% - var(--header-height) - var(--footer-height)) !important;
      display: grid;
      grid-template-areas: 'vMain' 'vBottom';

      .nonBlockingModal {
        top: 30%;
      }

      #mainVideoPlaceholder {
        height: 100%;
        width: 100%;
        min-height: 100%;

        .userStatusContainer {
          bottom: 0px !important;
        }
      }
      #participantsPlaceholder {
        grid-area: vBottom;
        height: 100%;
        width: 100%;
        overflow-y: auto;

        .videosContainer {
          grid-template-areas: none !important;
          border-top: 1px solid #ccc;
          border-left: none;

          .videoContainer {
            height: 50px;
            width: 100%;
            margin-bottom: 5px;
            display: grid;
            grid-template-areas: 'videoSM userStatus userStatus userStatus userStatus';

            .videoContainer_noCamera {
              position: relative;
            }

            video {
              grid-area: videoSM;
              position: absolute;
              width: 100%;
              height: 100%;
            }

            .slowConnection {
              display: none;
            }

            .userStatusContainer {
              grid-area: userStatus;
              justify-content: flex-start;
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              transform: none;
              text-align: left;
              width: 100%;

              .micFeedback {
                top: 0px;
                margin-top: 0 !important;
                align-self: center !important;
                position: relative;
              }
            }
          }
        }
      }
    }
  }

  /** usamos 560px para que este css seja aplicado em celulares na horizontal. */
  @media (max-height: 560px) {
    --header-height: 0px !important;
    --footer-height: 65px;

    :root {
      --header-height: 0px !important;
      --footer-height: 65px;
    }

    &.myRoom.ready .header {
      height: var(--header-height);
      display: none;
    }

    &.myRoom.ready #body {
      height: calc(100% - var(--header-height) - var(--footer-height)) !important;
      top: var(--header-height);

      #mainVideoPlaceholder {
        .userStatusContainer {
          bottom: 0px !important;
        }
      }

      #participantsPlaceholder .videosContainer .videoContainer {
        height: 100px;

        video {
          height: 100px;
        }
        .userStatusContainer {
          top: 79%;
        }
      }
    }

    #footer {
      height: var(--footer-height);

      .toolbar {
        height: var(--footer-height);
      }
    }
  }

  @media (max-width: 550px) {
    .myRoom.ready .header {
      display: none;
    }
  }
}
