#popoverSuporte {
  z-index: 10001;
  background-color: var(--customer-theme-secondary-color);
  min-width: 300px;
}

#popoverSuporte .toggle {
  background-color: var(--customer-theme-secondary-color);
}

#popoverSuporte .accordion *,
#popoverSuporte .accordion-button {
  background-color: var(--customer-theme-secondary-color) !important;
  color: #fff !important;
}

.icon.whatsapp {
  width: 18px;
  filter: brightness(0), invert(1);
}

.icon.centeredLayout {
  transform: rotate(180deg);
  width: 45px;
}

#popoverSuporte .layouts button {
  border: none;
  width: 33%;
  height: 60px;
}

#popoverSuporte button:hover,
#popoverSuporte button:focus {
  filter: contrast(0.7);
}

#popoverSuporte .accordion-button {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

#popoverSuporte .accordion-body {
  padding: 0;
}

#popoverSuporte .suporte a {
  flex-grow: 1;
  cursor: pointer;
}
#popoverSuporte .suporte a:hover,
#popoverSuporte .suporte a:focus {
  filter: contrast(0.7) !important;
}

#popoverSuporte .accordion-button:focus {
  border: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0.1rem rgba(255, 255, 255, 0.5);
}

#popoverSuporte .accordion-button::after {
  /* transforma em branco */
  filter: brightness(0) invert(1);
}

#pvDrawer {
  background-color: transparent;
  width: 100%;
  height: calc(100% - var(--header-height));
  margin-top: var(--header-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: rgba(55, 55, 55, 0.9);
}

#pvDrawer h3 {
  color:#fff;
  margin-bottom: 30px;

  overflow: hidden;
}

#pvDrawer #testsList {
  visibility: hidden;
  display: flex;
  flex-direction: column;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

#body.drawerOpen ~ aside #pvDrawer #testsList {
  animation: 0.3s fadeIn;
  animation-fill-mode: forwards;
}


#pvDrawer #testsList button {
 flex: 0;
}

#pvDrawer #PVcloseTestButton {
  position: absolute;
  right: 10px;
  top: calc(var(--header-height) + 10px);
  background-color: transparent;
  border: none;
  
}

#pvDrawer #PVcloseTestButton * {
   color: rgb(255,0,0,1);
   filter: brightness(0.95);
}

#pvDrawer #PVcloseTestButton:hover * {
  filter: brightness(1);
}

