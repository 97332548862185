.login-form {
  margin-top: 20%;
  background-color: lightgrey;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 5px 5px 20px 2px rgb(0 0 0 / 70%);
}

#roomId {
  font-size: 1em;
  padding-left: 1em;
  color: #495057;
}
