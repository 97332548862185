@import '~bootstrap/scss/bootstrap';
:root {
  --header-height: 80px;
  --footer-height: 80px;
  --customer-primary-color: #ddeeff;
  --customer-primary-textColor: #565454;
  --customer-secondary-color: black;
  --customer-secondary-textColor: black;
}

.prontuarioBtnContainer,
.prontuarioImg {
  display: none;
}

body {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #ddd;
}

* {
  box-sizing: border-box;
}

body * {
  transition: all 0.3s ease-in-out;
}

header * {
  transition: none;
}

#body {
  height: calc(100% - (var(--header-height)) - var(--footer-height));
  position: relative;
}
#body.drawerOpen {
  width: 60vw;
}

.container {
  height: 100%;
}

.layoutBtnContainer img {
  width: 23px;
  transform: rotate(180deg);
  filter: brightness(10);
  margin-top: -2px;
}

.container-fluid {
  padding: 0px !important;
}

header {
  padding-left: 20px;
  padding-right: 0px;
  z-index: 1000;
  margin: 0px !important;
  height: var(--header-height);
}

.centerHeader {
  font-family: 'Trebuchet MS';
  label {
    margin-bottom: 0px;
  }
}

.warning-container {
  background: #fff;
  width: 300px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1050;
  border-radius: 10px;
  box-shadow: 5px 5px 20px 2px rgb(0 0 0 / 50%);
}

.warning-header {
  border-bottom: 1px solid #ccc;
  padding: 10px 10px 10px 10px;
}
.warning-body {
  padding: 10px 10px 10px 10px;
  text-align: center;
}

#mainVideoPlaceholder {
  display: none;
}

.awaitingParticipants {
  position: fixed;
  top: var(--header-height);
  left: 50%;
  transform: translateX(-50%);
  z-index: 1001;
  padding: 0 15px 0 15px;
  background-color: #ddd;
  border-radius: min(10px, var(--header-height)) min(10px, var(--header-height)) 10px 10px;
  box-shadow: 2px 4px 6px 2px rgb(0 0 0 / 50%);
}

.videoFlip,
.flip {
  transform: rotateY(180deg);
}

.centerToaster {
  margin-bottom: 40vh;
  text-align: center;
}

.userStatusContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  .micFeedback {
    align-self: flex-end;
    width: 25px;
  }
}

.localVideoContainer {
  .localVideo {
    object-fit: cover;
    border-radius: 10px 10px 0 0;
    padding: 0px !important;

    width: 100%;
    height: min(100%, 300px);
    background: #222;
    transform: rotateY(180deg);
  }

  .name {
    display: none;
  }

  .userStatusContainer {
    display: flex;
    align-items: center;
    position: relative;
    text-align: center;
    top: -30px;
    height: 0;
  }

  .videoContainer_noCamera {
    background-color: #000 !important;
    background-size: contain;
    width: 100%;
    height: min(50vh, 18em);
    margin-bottom: 0px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .avisoCameraDesligada {
    color: #fff;
    top: -50px;
    width: 100%;
    text-align: center;
    display: inline-block;
    position: relative;
    z-index: 5;
  }
}

.slowConnectionOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(10, 10, 10, 0.4);
}
.fa-fw.slowConnection {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translatex(-50%);
  padding: 22px;
  font-size: 22px;
  width: 100%;

  p {
    font-size: 16px;
    color: #fff;
    opacity: 1;
    text-shadow: 1px 1px 1px #222;
    margin-top: 15px;
  }

  .icon.slowConnection {
    position: absolute;
    color: #ff0000;
    z-index: 65000;
    animation: glow 1s linear infinite;
    &.fa-slash {
      color: #ce0000;

      transform: rotate(90deg);
    }
  }
}

@keyframes glow {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

@keyframes rotate {
  0% {
    rotate: 0deg;
  }
  25% {
    rotate: 90deg;
  }
  50% {
    rotate: 180deg;
  }
  75% {
    rotate: 270deg;
  }

  100% {
    rotate: 360deg;
  }
}

.videosContainer {
  height: 100%;
  display: none;

  .videoContainer {
    height: 100%;
    position: relative;

    .userStatusContainer {
      position: relative;
      text-align: center;
      top: 90%;
      left: 50%;
      transform: translateX(-50%);

      .micFeedback {
        display: inline-block;
        align-self: flex-end;
        top: -5px;
        left: 2px;
        width: 25px;
        position: relative;
        margin-top: 0px;
      }
    }

    video {
      background-color: transparent;
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
}

.panelRight {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 20px 2px rgb(0 0 0 / 50%);

  .localVideoContainer {
    height: min(50vh, 18em);

    .videoContainer {
      height: 100%;
    }
  }
}

.pitchBar {
  height: 0.1em;
  box-shadow: 0px 0px 3px;
}

.myRoom.ready {
  header {
    position: fixed;
    left: 0;
    width: 100%;

    .leftHeader {
      background-repeat: no-repeat;
    }
  }

  .panelRight {
    background-color: transparent;
    width: 200px !important;
    position: fixed;
    right: 20px;
    top: 100px;
  }

  #body {
    top: var(--header-height);
  }

  .localVideo {
    width: 300px !important;
    height: 200px !important;
    border-radius: 10px;
  }
}

.name {
  display: inline-block;
  margin-left: 5px;
  color: #ffffff;
  text-shadow: black 1px 1px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.micFeedback {
  transform: rotate(180deg);
}

.remoteMic,
.remoteCam {
  color: #fff;
  width: 15px !important;
  height: 15px !important;
  border-radius: 15px;
  background-color: #ff0000;
  margin-left: 5px;
  padding: 3px;
}

#footer {
  width: 100%;
  position: fixed;
  height: var(--footer-height);
  bottom: 0px;
  left: 0px;

  .toolbar {
    width: 100%;
    height: 100%;

    display: flex;

    justify-content: center;

    background-color: rgba(55, 55, 55, 0.7);
    padding: 5px 50px 0px 50px !important;
  }
}

.myRoom,
.videosContainer {
  height: 100%;
}

.btnRecordtime {
  display: none;
}

.toolbar button {
  background: transparent;
  margin-left: 10px;
  margin-right: 10px;
  border: solid 1px #aaa;
  font-size: 15px;
}
.toolbar button .icon {
  font-size: 20px;
  color: #fff;
  margin: 0px 0px 0px 0px;
  width: 20px;
}

.toolbar button .icon[data-icon='phone'] {
  transform: rotate(225deg);
  margin-top: 3px;
}

.toolbar button:focus {
  outline: none;
}
.toolbar button.disabled {
  background-color: #ee3333;
  border: 1px solid #ee3333;
}

.toolbar button.active {
  background-color: #334fee;
  border: 1px solid #334fee;
}

.toolbar button.disabled:hover {
  background: #ff5555;
}

.toolbar button.enabled:hover {
  background: rgba(120, 120, 120, 0.5);
}

.controls .svg-inline--fa {
  width: 20px;
}

.custom-switch {
  display: inline-block !important;
}

.rcw-launcher {
  width: 50px !important;
  height: 50px !important;
}

.chatContainer {
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 400px;
  width: 350px;
}

//Ajustes para mobile
@include media-breakpoint-down(md) {
  :root {
    --footer-height: 65px;
  }
  header {
    padding-left: 0px;
  }

  .leftHeader {
    background-position: center;
    border-right: none;
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    text-align: center;
    border-right: none !important;
  }

  .centerHeader,
  .rightHeader {
    display: none;
  }

  .layoutBtnContainer {
    display: none;
  }

  #body {
    top: 0px;
  }

  .ready #body {
    top: var(--header-height);
  }

  .panelRight {
    margin-top: 10px;
  }

  .videosContainer {
    .awaitingParticipants {
      width: fit-content;
      text-align: center;
    }
  }

  .ready .panelRight {
    bottom: 0px;
    right: 0px;
    width: 25% !important;
  }

  .ready .panelRight .localVideo {
    width: 100% !important;
    height: 100px !important;
  }

  .btn-circle.btn-md {
    width: 50px;
    height: 35px;
    padding: 5px 12px;
    border-radius: 25px;
    font-size: 7px;
    text-align: center;
  }

  .ready .localName {
    display: none;
  }

  .roomId {
    display: none;
  }

  #footer .toolbar {
    background-color: rgba(55, 55, 55, 0.7);
    justify-content: space-evenly;
    height: var(--footer-height);
    text-align: center;
    width: 100%;

    .up_arrow {
      display: none;
    }
  }

  .btn-circle-container {
    width: 65px;
  }

  .btn-circle-container label {
    top: 34px;
    left: -62px;
  }

  .chatContainer {
    bottom: calc(var(--footer-height) + 2px);
    max-width: 100%;
    height: calc(100% - var(--header-height) - var(--footer-height) - 5px);
  }
}
