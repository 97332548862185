.gridLayout {
  #mainVideoPlaceholder {
    display: none;
  }
  #participantsPlaceholder {
    height: 100%;
  }

  &.ready {
    #participantsPlaceholder {
      .grid {
        display: grid;
        position: absolute;
        width: 100%;
        height: 100%;
        gap: 5px;

        .videoContainer video {
          background-color: #222;
          border-radius: 10px;
        }
      }

      .grid1 {
        grid-template-areas: 'v0';

        .videoContainer video {
          background-color: transparent;
          border-radius: 10px;
          max-width: unset !important;
          object-fit: contain !important;
        }
      }

      .grid2 {
        grid-template-areas: 'v0 v1';
        height: 100%;
        max-height: 600px;
        width: 90%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        gap: 20px;
      }

      .grid3 {
        grid-template-areas:
          '. v0 v0 v1 v1 .'
          '. . v2 v2 . .';
      }

      .grid4 {
        grid-template-areas:
          '. v0 v0 v1 v1 .'
          '. v2 v2 v3 v3 .';
      }

      .grid5 {
        grid-template-areas:
          'v0 v0 v1 v1 v4 v4'
          '. v2 v2 v3 v3 .';
      }
      .grid6 {
        grid-template-areas:
          'v0 v0 v1 v1 v4 v4'
          'v2 v2 v3 v3 v5 v5';
      }
      .grid7 {
        grid-template-areas:
          'v0 v0 v0 v1 v1 v1 v4 v4 v4 v6 v6 v6'
          'v2 v2 v2 v2 v3 v3 v3 v3 v5 v5 v5 v5';
      }
      .grid8 {
        grid-template-areas:
          'v0 v1 v4 v6'
          'v2 v3 v5 v7';
      }
      .grid9 {
        grid-template-areas:
          'v0 v1 v4 v6'
          'v2 v3 v5 v7'
          '.  v8 v8 .';
      }
      .grid10 {
        grid-template-areas:
          'v0 v1 v4 v6'
          'v2 v3 v5 v7'
          '.  v8 v9 .';
      }

      .grid11 {
        grid-template-areas:
          'v0 v0 v0 v1 v1 v1 v4 v4 v4 v6 v6 v6'
          'v2 v2 v2 v3 v3 v3 v5 v5 v5 v7 v7 v7'
          'v8 v8 v8 v8 v9 v9 v9 v9 v10 v10 v10 v10';
      }
      .grid12 {
        grid-template-areas:
          'v0 v1 v4 v6'
          'v2 v3 v5 v7'
          'v8 v9 v10 v11';
      }

      .grid13 {
        grid-template-areas:
          'v0 v1 v4 v6 v12'
          'v2 v3 v5 v7 v11'
          '. v8 v9 v10  .';
      }

      .grid14 {
        grid-template-areas:
          'v0 v1 v4 v6 v12'
          'v2 v3 v5 v7 v13 '
          'v8 v9 v10 v11 .';
      }

      .grid15 {
        grid-template-areas:
          'v0 v1 v4 v6 v12'
          'v2 v3 v5 v7 v13 '
          'v8 v9 v10 v11 v14';
      }

      .grid16 {
        grid-template-areas:
          'v0  v1 v4  v6  v12'
          'v2  v3 v5  v7  v13 '
          'v8  v9 v10 v11 v14'
          '.   .  v15   .   .';
      }

      .grid17 {
        grid-template-areas:
          'v0  v1 v4  v6  v12'
          'v2  v3 v5  v7  v13 '
          'v8  v9 v10 v11 v14'
          'v15 v15  . v16 v16';
      }

      .grid18 {
        grid-template-areas:
          'v0  v1  v4  v6  v12'
          'v2  v3  v5  v7  v13 '
          'v8  v9  v10 v11 v14'
          '.   v15 v16 v17  .';
      }

      .grid19 {
        grid-template-areas:
          'v0  v1  v4  v6  v12'
          'v2  v3  v5  v7  v13 '
          'v8  v9  v10 v11 v14'
          'v15 v16 v17  v18  .';
      }

      .grid20 {
        grid-template-areas:
          'v0  v1  v4  v6  v12'
          'v2  v3  v5  v7  v13 '
          'v8  v9  v10 v11 v14'
          'v15 v16 v17 v18 v19';
      }

      .grid21 {
        grid-template-areas:
          'v0  v1  v4  v6  v12'
          'v2  v3  v5  v7  v13 '
          'v8  v9  v10 v11 v14'
          'v15 v16 v17 v18 v19'
          '.   .   v20 .   .';
      }

      .grid22 {
        grid-template-areas:
          'v0  v1  v4  v6  v12'
          'v2  v3  v5  v7  v13 '
          'v8  v9  v10 v11 v14'
          'v15 v16 v17 v18 v19'
          'v20 v20 .   v21 v21';
      }

      .grid23 {
        grid-template-areas:
          'v0  v1  v4  v6  v12'
          'v2  v3  v5  v7  v13 '
          'v8  v9  v10 v11 v14'
          'v15 v16 v17 v18 v19'
          '.   v20 v21 v22 .';
      }

      .grid24 {
        grid-template-areas:
          'v0  v1  v4  v6  v12'
          'v2  v3  v5  v7  v13 '
          'v8  v9  v10 v11 v14'
          'v15 v16 v17 v18 v19'
          'v20 v21 .   v22 v23';
      }

      .grid25 {
        grid-template-areas:
          'v0  v1  v4  v6  v12'
          'v2  v3  v5  v7  v13 '
          'v8  v9  v10 v11 v14'
          'v15 v16 v17 v18 v19'
          'v20 v21 v22 v23 v24';
      }

      .grid26,
      .grid27,
      .grid28,
      .grid29,
      .grid30 {
        grid-template-areas:
          'v0  v1  v4  v6  v12 v25'
          'v2  v3  v5  v7  v13 v26'
          'v8  v9  v10 v11 v14 v27'
          'v15 v16 v17 v18 v19 v28'
          'v20 v21 v22 v23 v24 v29';
      }
    }

    .videosContainer {
      display: grid;
      position: relative;

      .remoteVideoContainer {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }

    .remoteVideoContainer .videoContainer_noCamera {
      background-size: 300px;
      width: 100%;
      height: 100%;
      position: absolute;
      background-repeat: no-repeat;
      background-position: center;
      background-color: #222;
      border-radius: 10px;
    }
  }

  .videoContainer.myVideoContainer {
    position: absolute;
    right: 0px;
    width: 200px;
    height: 150px;
    z-index: 1000;

    .myVideo {
      transform: rotateY(180deg);
    }
    .userStatusContainer {
      top: 85%;
      .name {
        display: none;
      }
    }
  }

  @media (max-width: 991.98px) and (min-height: 601px) {
    --footer-height: 55px;
    :root {
      --footer-height: 55px;
    }
  }
}
