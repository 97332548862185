.chatContainer {
  z-index: 10001;
}

#chatContainer {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

#chatToolbar {
  height: 30px;
  background-color: var(--customer-primary-color, #5360d3);
  box-sizing: border-box;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  padding-top: 4px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: var(--customer-primary-textColor, #ffffff);
}

#closeButton {
  position: absolute;
  right: 10px;
  top: 8px;
  cursor: pointer;
}
#closeButton:hover {
  color: rgb(235, 231, 231);
}

#closeButton .MuiSvgIcon-colorAction {
  color: var(--customer-primary-textColor);
}

#chatComponent {
  background-color: #fafafa;
  z-index: 99999;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.message-wrap {
  padding: 0 15px;
  height: calc(100% - 80px);
  overflow: auto;
}

.message {
  position: relative;
  padding: 7px 0;
}
.user-img {
  position: absolute;
  border-radius: 45px;
  width: 60px;
  height: 60px;
  top: 15px;
}

.msg-detail {
  width: calc(100% - 65px);
  display: inline-block;
}

.msg-detail p {
  margin: 0;
  font-size: 15px;
}

.msg-info p {
  font-size: 0.8em;
  color: #000000;
  font-style: italic;
}

.msg-content {
  position: relative;
  margin-top: 5px;
  border-radius: 5px;
  padding: 8px;
  color: #000000;
  width: auto;
  max-width: 80%;
}

span.triangle {
  border-radius: 2px;
  height: 8px;
  width: 8px;
  top: 12px;
  display: block;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
}

.text {
  word-break: break-word;
  color: #000000;
}

/* Start message from other user */

.message > .left .msg-detail .msg-info {
  text-align: left;
}

.message.left .msg-detail {
  padding-left: 65px;
}

.message.left .user-img {
  left: -5px;
  border: 1px solid #f0f0f094;
}

.message.left .msg-detail .msg-content {
  background-color: #dcdada;
  float: left;
}
.message.left .msg-detail .msg-content span.triangle {
  background-color: #dcdada;
  border-bottom-width: 0;
  border-left-width: 0;
  left: -5px;
}

/* End message from other user */

/* Start my messages */

.message.right .msg-detail .msg-info {
  text-align: right;
}
.message.right .user-img {
  right: -5px;
  border: 1px solid #c8ffe8ab;
}

.message.right .msg-detail .msg-content {
  background-color: #c8ffe8;
  float: right;
}
.message.right .msg-detail .msg-content span.triangle {
  background-color: #c8ffe8;
  border-bottom-width: 0;
  border-left-width: 0;
  right: -5px;
}

.message.typeERROR img,
.message.typeFILE_UPLOAD img {
  display: none;
}

.message.typeERROR .msg-detail,
.message.typeFILE_UPLOAD .msg-detail {
  width: 100%;
}

.message.typeERROR .msg-detail .msg-info,
.message.typeFILE_UPLOAD .msg-detail .msg-info {
  display: none;
}

.message.typeERROR .msg-detail .msg-content,
.message.typeFILE_UPLOAD .msg-detail .msg-content {
  margin-left: auto;
  margin-right: auto;
  float: none;
}

.message.typeERROR .msg-detail .msg-content {
  background-color: #ffc8c8;
}

.message.typeFILE_UPLOAD .msg-detail .msg-content {
  background-color: #ccc;
  text-align: center;
}

.message.typeERROR .msg-detail .msg-content .triangle,
.message.typeFILE_UPLOAD .msg-detail .msg-content .triangle {
  display: none;
}

.message.typeERROR .msg-detail .msg-content p,
.message.typeFILE_UPLOAD .msg-detail .msg-content p {
  font-size: 13px;
}

.message .uploadProgress {
  display: none;
}

.message.typeFILE_UPLOAD .uploadProgress {
  display: block;
  width: 100%;
}

.message.typeFILE_UPLOAD .uploadProgress {
  display: block;
  width: 100%;
  border: 1px solid rgb(131, 131, 131);
  height: 5px;
}

.message.typeFILE_UPLOAD.DONE {
  transition: opacity 0.3s ease;
  opacity: 0;
  height: 0;
}

.message.typeFILE_UPLOAD .uploadProgress .currentProgress {
  background-color: #2230af;
  height: 100%;
}

/* End my messages */

#messageInput {
  display: flex;
  align-self: center;
  width: 98%;
  background-color: #ffffff;
  text-align: left;
  padding: 10px;
  height: 44px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 6px;
  border-top: 1px solid #ddd;
  justify-content: space-evenly;
}

#messageInput .dropzone button {
  border: none;
  background: #fff;
}
#messageInput .dropzone button:hover {
  border: none;
  background: #fff;
  color: #585555;
}

#messageInput input {
  width: 85%;
  border: none;
  outline: none;
  font-size: 14px;
  color: #000000;
  padding-left: 10px;
}

#sendButton {
  border: none;
  background: #fff;
  box-shadow: none !important;
  cursor: pointer;
}
#sendButton {
  margin-left: 3px !important;
}
#sendButton:hover {
  color: #585555;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #6b6b6b;
}

.chatComponentLight ::-webkit-scrollbar-thumb {
  background-color: #eeeeee !important;
}
