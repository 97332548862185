.nonBlockingModal {
  .modal-header {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #968c8c;
  }

  p {
    margin-bottom: 0.5rem;
  }
}
